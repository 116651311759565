// ComprasPage.tsx - Atualizada com controle de carrinho completo

import React, { useState, useEffect } from "react";
import HeaderComponent from "../components/HeaderComponent";
import MenuComponent from "../components/MenuComponent";
import { useNavigate } from "react-router-dom";
import { IoMdHome } from "react-icons/io";
import { Slide, ToastContainer, toast } from "react-toastify";
import { getVendedores } from "../services/vendedoresService";
import { getFazendas } from "../services/fazendaService";
import { getProdutosPorCategoria } from "../services/categoriasService";
import Cookies from "js-cookie";
import { Button, Offcanvas, Modal, Col } from "react-bootstrap";
import { IoCartOutline } from "react-icons/io5";
import { FaMinus, FaPlus, FaRegTrashAlt } from "react-icons/fa";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import {
  getCategorias,
  getSubcategoriasNivel1PorCategoria,
  getSubcategoriasNivel2PorCategoria,
} from "../services/categoriasService";

export interface Vendedor {
  id: number;
  nome: string;
  email: string;
  telefone: string;
  empresa: string;
  insumos: string;
}

interface InsumoVendedor {
  value: string;
  label: string;
  preco: string;
  categoria?: string;
}

interface Insumo {
  id: number;
  nome: string | null;
  subcategoria_nivel2_id: string | null;
  imagem: string | null;
}

interface CartItem {
  id: string;
  name: string;
  price: number;
  preco: string;
  quantity: number;
  sumPriceItem: number;
  image: string;
}

interface OptionType {
  value: number;
  label: string;
}

interface Categoria {
  id: number;
  descricao: string;
}

interface Sub1 {
  id: number;
  nome: string;
}

interface Sub2 {
  id: number;
  nome: string;
}

const ComprasPage: React.FC = () => {
  const [quantity, setQuantity] = useState(1);
  const [vendedores, setVendedores] = useState<Vendedor[]>([]);
  const [insumosList, setInsumosList] = useState<Insumo[]>([]);
  const [cartItems, setCartItems] = useState<CartItem[]>([]);
  const [showCart, setShowCart] = useState(false);
  const [quantities, setQuantities] = useState<{ [id: string]: number }>({});
  const navigate = useNavigate();

  const [categorias, setCategorias] = useState<Categoria[]>([]);
  const [subnivel1, setSubnivel1] = useState<Sub1[]>([]);
  const [subnivel2, setSubnivel2] = useState<Sub2[]>([]);

  const [categoriaSelecionada, setCategoriaSelecionada] =
    useState<OptionType | null>(null);
  const [subnivel1Selecionado, setSubnivel1Selecionado] =
    useState<OptionType | null>(null);
  const [subnivel2Selecionado, setSubnivel2Selecionado] =
    useState<OptionType | null>(null);

  const fetchVendedores = async () => {
    try {
      const data = await getVendedores();
      setVendedores(data);
    } catch (error) {
      console.error("Erro ao buscar vendedores:", error);
    }
  };

  const fetchInsumos = async () => {
    try {
      const queryParams: any = {};

      if (categoriaSelecionada) {
        queryParams.categoriaId = categoriaSelecionada.value;
      }
      if (subnivel1Selecionado) {
        queryParams.subcategoriaNivel1Id = subnivel1Selecionado.value;
      }
      if (subnivel2Selecionado) {
        queryParams.subcategoriaNivel2Id = subnivel2Selecionado.value;
      }

      const searchParams = new URLSearchParams(queryParams).toString();
      const data = await getProdutosPorCategoria(searchParams);
      setInsumosList(data);
    } catch (error) {
      console.error("Erro ao buscar insumos:", error);
      setInsumosList([]);
    }
  };

  const fetchFazendas = async () => {
    try {
      const fazendas = await getFazendas("");
      const idUser = Cookies.get("TokenBrasao");
      fazendas.filter((fazenda: any) => fazenda.idUser === idUser);
    } catch (error) {
      console.error("Erro ao buscar fazendas:", error);
    }
  };

  const updateCartDetails = () => {
    const storedCart = JSON.parse(localStorage.getItem("cart") || "[]");
    setCartItems(storedCart);
  };

  const sendWhatsApp = () => {
    const totalQuantidade = cartItems.reduce(
      (acc, item) => acc + item.quantity,
      0
    );
    const totalValor = cartItems.reduce(
      (acc, item) => acc + item.sumPriceItem,
      0
    );

    const message = cartItems
      .map(
        (item) =>
          `*${item.name}* - Quantidade: ${item.quantity} - Total: ${formatCurrency(item.sumPriceItem)}`
      )
      .join("%0A");

    const fullMessage = `Pedido:%0A${message}%0A%0A*Total de Itens:* ${totalQuantidade}%0A*Valor Total:* ${formatCurrency(totalValor)}`;

    const url = `https://wa.me/5534999115556?text=${fullMessage}`;
    window.open(url, "_blank");
    clearCart();
    handleCloseResumoModal();
  };

  const addToCart = (insumo: InsumoVendedor, parentInsumo: Insumo | null) => {
    if (!parentInsumo) {
      toast.error("Produto inválido.");
      return;
    }

    const preco = parseFloat(insumo.preco);
    const qtdAtual = quantities[insumo.value] || 1;

    const existingCart: CartItem[] = JSON.parse(
      localStorage.getItem("cart") || "[]"
    );

    const itemIndex = existingCart.findIndex(
      (item) => item.id === insumo.value
    );

    if (itemIndex > -1) {
      // Atualiza a quantidade e o valor total do item já existente
      existingCart[itemIndex].quantity += qtdAtual;
      existingCart[itemIndex].sumPriceItem =
        existingCart[itemIndex].quantity * existingCart[itemIndex].price;
    } else {
      // Cria novo item
      const newItem: CartItem = {
        id: insumo.value,
        name: insumo.label,
        price: preco,
        preco: preco.toFixed(2),
        quantity: qtdAtual,
        sumPriceItem: preco * qtdAtual,
        image: parentInsumo.imagem || "",
      };
      existingCart.push(newItem);
    }

    localStorage.setItem("cart", JSON.stringify(existingCart));
    setCartItems(existingCart);
    toast.success("Item adicionado ao carrinho com sucesso!");
    setShowCart(true);
  };

  const exibeCarrinho = () => {
    setShowCart(true);
  };

  const removeFromCart = (id: string) => {
    const updatedCart = cartItems.filter((item) => item.id !== id);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
    setCartItems(updatedCart);
  };

  const clearCart = () => {
    localStorage.removeItem("cart");
    setCartItems([]);
  };

  const [showResumoModal, setShowResumoModal] = useState(false);

  const handleShowResumoModal = () => setShowResumoModal(true);
  const handleCloseResumoModal = () => setShowResumoModal(false);

  const incrementQty = (id: string) => {
    setQuantities((prev) => ({
      ...prev,
      [id]: (prev[id] || 1) + 1,
    }));
  };

  const decrementQty = (id: string) => {
    setQuantities((prev) => ({
      ...prev,
      [id]: Math.max((prev[id] || 1) - 1, 1),
    }));
  };

  useEffect(() => {
    const fetchCategorias = async () => {
      const response = await getCategorias();
      setCategorias(response);
    };
    fetchCategorias();
  }, []);

  useEffect(() => {
    fetchVendedores();
    fetchInsumos();
    fetchFazendas();
    updateCartDetails();
  }, []);

  useEffect(() => {
    const fetchSubsNivel1 = async () => {
      if (categoriaSelecionada?.value) {
        const result = await getSubcategoriasNivel1PorCategoria(
          categoriaSelecionada.value
        );
        setSubnivel1(result);
        setSubnivel1Selecionado(null);
        setSubnivel2([]);
        setSubnivel2Selecionado(null);
      }
    };
    fetchSubsNivel1();
  }, [categoriaSelecionada]);

  useEffect(() => {
    const fetchSubsNivel2 = async () => {
      if (subnivel1Selecionado?.value && categoriaSelecionada?.value) {
        const result = await getSubcategoriasNivel2PorCategoria(
          categoriaSelecionada.value
        );
        setSubnivel2(result);
        setSubnivel2Selecionado(null);
      }
    };
    fetchSubsNivel2();
  }, [subnivel1Selecionado]);

  useEffect(() => {
    fetchInsumos();
  }, [categoriaSelecionada, subnivel1Selecionado, subnivel2Selecionado]);

  const formatCurrency = (value: number) =>
    new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);

  return (
    <>
      <HeaderComponent />
      <Offcanvas
        show={showCart}
        onHide={() => setShowCart(false)}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Meu carrinho</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {cartItems.length > 0 && (
            <div className="row cemPor">
              <button
                onClick={handleShowResumoModal}
                className="btn btn-sm btn-primary br-1 mb-1 float-end"
              >
                Enviar pedido
              </button>
            </div>
          )}
          {cartItems.length > 0 ? (
            cartItems.map((item) => (
              <div key={item.id} className="mb-1">
                <hr />
                <div className="row cemPor">
                  <div className="col-md-4">
                    {item.image ? (
                      <img
                        src={item.image}
                        className="card-img-top"
                        alt={item.name || "Imagem"}
                        style={{
                          height: "100px",
                          objectFit: "cover",
                        }}
                      />
                    ) : (
                      <div
                        className="card-img-top d-flex align-items-center justify-content-center"
                        style={{
                          height: "100px",
                          backgroundColor: "#f0f0f0",
                        }}
                      >
                        Sem imagem
                      </div>
                    )}
                  </div>
                  <div className="col-md-8">
                    <h6>{item.name}</h6>
                    <p className="mb-0">
                      Valor: <b>{formatCurrency(item.price)}</b>
                    </p>
                    <p className="mb-0">Quantidade: {item.quantity}</p>
                    <p className="mb-0">
                      Total: <b>{formatCurrency(item.price * item.quantity)}</b>
                    </p>
                  </div>
                  <button
                    onClick={() => removeFromCart(item.id)}
                    className="btn btn-sm btn-dark br-1 mb-1 pt-1 float-end d-flex ms-2 mt-3"
                    style={{ width: "auto" }}
                  >
                    <FaRegTrashAlt
                      style={{ cursor: "pointer", marginTop: "3px" }}
                    />
                    &nbsp; Remover item do Carrinho
                  </button>
                </div>
              </div>
            ))
          ) : (
            <p className="text-center">
              <strong>Carrinho vazio</strong>
            </p>
          )}
          {cartItems.length > 0 && (
            <div className="row cemPor mt-4">
              <button
                onClick={clearCart}
                className="btn btn-sm btn-dark br-1 mb-1 float-end"
              >
                Esvaziar Carrinho
              </button>
            </div>
          )}
        </Offcanvas.Body>
      </Offcanvas>
      <div className="page-content">
        <MenuComponent />
        <div className="content-wrapper">
          <div className="page-header page-header-light">
            <div className="breadcrumb-line breadcrumb-line-light header-elements-lg-inline">
              <div className="d-flex">
                <div className="breadcrumb">
                  <a onClick={() => navigate("/")} className="breadcrumb-item">
                    <IoMdHome /> &nbsp; Início
                  </a>
                  <span className="breadcrumb-item active">Compras</span>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="row mb-4">
                    <div className="row mb-3">
                      <Col lg={4}>
                        <Button
                          onClick={() => exibeCarrinho()}
                          variant="primary"
                          style={{
                            float: "left",
                            marginLeft: "0",
                            display: "flex",
                            marginTop: "10px",
                          }}
                        >
                          Visualizar Carrinho
                          <IoCartOutline
                            style={{
                              marginTop: "3px",
                              marginLeft: "10px",
                            }}
                          />
                        </Button>
                      </Col>
                    </div>
                    <Col lg={4}>
                      <label>Categoria:</label>
                      <Select
                        options={categorias.map((cat) => ({
                          value: cat.id,
                          label: cat.descricao,
                        }))}
                        value={categoriaSelecionada}
                        onChange={setCategoriaSelecionada}
                        placeholder="Selecione a Categoria"
                      />
                    </Col>

                    <Col lg={4}>
                      <label>Subcategoria Nível 1:</label>
                      <Select
                        options={subnivel1.map((sub1) => ({
                          value: sub1.id,
                          label: sub1.nome,
                        }))}
                        value={subnivel1Selecionado}
                        onChange={setSubnivel1Selecionado}
                        placeholder="Selecione a Subcategoria Nível 1"
                        isDisabled={!categoriaSelecionada}
                      />
                    </Col>

                    <Col lg={4}>
                      <label>Subcategoria Nível 2:</label>
                      <Select
                        options={subnivel2.map((sub2) => ({
                          value: sub2.id,
                          label: sub2.nome,
                        }))}
                        value={subnivel2Selecionado}
                        onChange={setSubnivel2Selecionado}
                        placeholder="Selecione a Subcategoria Nível 2"
                        isDisabled={!subnivel1Selecionado}
                      />
                    </Col>
                  </div>

                  <div className="row mb-4">
                    {vendedores.map((vendedor) => {
                      let insumosParsed: InsumoVendedor[] = [];
                      try {
                        insumosParsed =
                          JSON.parse(vendedor.insumos || "[]") || [];
                      } catch (e) {
                        console.error("Erro no JSON de insumos", e);
                      }
                      return insumosParsed.map((insumo, index) => {
                        const parentInsumo = insumo.value
                          ? insumosList.find(
                              (item) => item.id.toString() === insumo.value
                            )
                          : undefined;
                        const safeParentInsumo: Insumo | null =
                          parentInsumo ?? null;
                        return (
                          safeParentInsumo && (
                            <div
                              key={`${safeParentInsumo.id}-${index}`}
                              className="col-md-4 mb-3"
                            >
                              <div className="card">
                                {safeParentInsumo.imagem ? (
                                  <img
                                    src={safeParentInsumo.imagem}
                                    className="card-img-top"
                                    alt={safeParentInsumo.nome || "Imagem"}
                                    style={{
                                      height: "200px",
                                      objectFit: "cover",
                                    }}
                                  />
                                ) : (
                                  <div
                                    className="card-img-top d-flex align-items-center justify-content-center"
                                    style={{
                                      height: "200px",
                                      backgroundColor: "#f0f0f0",
                                    }}
                                  >
                                    Sem imagem
                                  </div>
                                )}
                                <div className="card-body">
                                  <h5 className="card-title mb-0">
                                    {safeParentInsumo.nome}
                                  </h5>
                                  <p className="card-text">
                                    Insumo: {insumo.label}
                                  </p>
                                  <p className="card-text">
                                    <b>R${insumo.preco}</b>
                                  </p>
                                  <div className="d-flex align-items-center gap-2 mt-2">
                                    <Button
                                      variant="outline-secondary"
                                      onClick={() => decrementQty(insumo.value)}
                                    >
                                      -
                                    </Button>
                                    <span
                                      style={{
                                        minWidth: "30px",
                                        textAlign: "center",
                                      }}
                                    >
                                      {quantities[insumo.value] || 1}
                                    </span>
                                    <Button
                                      variant="outline-secondary"
                                      onClick={() => incrementQty(insumo.value)}
                                    >
                                      +
                                    </Button>
                                  </div>
                                  <Button
                                    variant="primary"
                                    onClick={() =>
                                      addToCart(insumo, safeParentInsumo)
                                    }
                                    style={{
                                      float: "left",
                                      marginLeft: "0",
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    Adicionar ao carrinho
                                    <IoCartOutline
                                      style={{
                                        marginTop: "3px",
                                        marginLeft: "10px",
                                      }}
                                    />
                                  </Button>
                                </div>
                              </div>
                            </div>
                          )
                        );
                      });
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showResumoModal} onHide={handleCloseResumoModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title className="text-center cemPor">
            Deseja solicitar este pedido via whatsapp? <br />
            Após o envio o carrinho de compras será esvaziado.
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {cartItems.length > 0 ? (
            <div className="table-responsive">
              <table className="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th>Produto</th>
                    <th>Preço Unitário</th>
                    <th>Quantidade</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {cartItems.map((item) => (
                    <tr key={item.id}>
                      <td>{item.name}</td>
                      <td>{formatCurrency(item.price)}</td>
                      <td>{item.quantity}</td>
                      <td>{formatCurrency(item.sumPriceItem)}</td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan={2}>
                      <strong>Total Geral</strong>
                    </td>
                    <td>
                      <strong>
                        {cartItems.reduce(
                          (acc, item) => acc + item.quantity,
                          0
                        )}
                      </strong>
                    </td>
                    <td>
                      <strong>
                        {formatCurrency(
                          cartItems.reduce(
                            (acc, item) => acc + item.sumPriceItem,
                            0
                          )
                        )}
                      </strong>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          ) : (
            <p>O carrinho está vazio.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseResumoModal}>
            Cancelar
          </Button>
          <Button
            variant="success"
            onClick={() => {
              sendWhatsApp();
              clearCart();
              handleCloseResumoModal();
              toast.success("Pedido enviado com sucesso via WhatsApp!");
            }}
          >
            Enviar via WhatsApp
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        transition={Slide}
      />
    </>
  );
};

export default ComprasPage;
