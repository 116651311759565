import Cookies from "js-cookie";

const API_URL = "https://bf-api.solutioweb.com.br/categorias";

export const getCategoriaById = async (id: number) => {
  try {
    const response = await fetch(`${API_URL}/${id}`);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error("Error fetching conta bancaria:", error);
    throw error;
  }
};

export const getCategorias = async (search = "") => {
  try {
    const url = search
      ? `${API_URL}/lista?search=${encodeURIComponent(search)}`
      : `${API_URL}/lista`;
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error("Error fetching contas bancarias:", error);
    throw error;
  }
};

export const getSubcategorias = async (search = "") => {
  try {
    const url = search
      ? `${API_URL}/lista-subcategorias?search=${encodeURIComponent(search)}`
      : `${API_URL}/lista-subcategorias`;
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error("Error fetching contas bancarias:", error);
    throw error;
  }
};

export const uploadCategoriaImage = async (
  categoriaId: any,
  base64Image: any
) => {
  try {
    const token = Cookies.get("TokenBrasao");
    const formData = new FormData();
    formData.append("categoria_id", categoriaId);
    formData.append("imagem", base64Image);

    const response = await fetch(API_URL + "/uploadImage", {
      method: "POST",
      body: formData,
    });

    if (!response.ok) {
      throw new Error("Falha na requisição");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Erro ao enviar a imagem:", error);
    throw error;
  }
};

export const uploadProdutoImage = async (produtoId: any, base64Image: any) => {
  try {
    const token = Cookies.get("TokenBrasao");
    const formData = new FormData();
    formData.append("produto_id", produtoId);
    formData.append("imagem", base64Image);

    const response = await fetch(API_URL + "/uploadImageProduct", {
      method: "POST",
      body: formData,
    });

    if (!response.ok) {
      throw new Error("Falha na requisição");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Erro ao enviar a imagem:", error);
    throw error;
  }
};

export const createCategoria = async (conta: any) => {
  try {
    const token = Cookies.get("TokenBrasao");

    const formData = new FormData();

    formData.append("descricao", conta.descricao);

    const response = await fetch(API_URL, {
      method: "POST",
      body: formData,
    });

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Falha ao cadastrar a conta bancaria:", error);
    throw error;
  }
};

export const updateCategoria = async (conta: any) => {
  try {
    const token = Cookies.get("TokenBrasao");

    const formData = new FormData();
    formData.append("descricao", conta.descricao);

    const response = await fetch(`${API_URL}/${conta.id}`, {
      method: "PUT",
      body: formData,
    });

    if (!response.ok) {
      return { error: "Houve uma falha. Tente mais tarde." };
    }

    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error("Error updating conta bancaria:", error);
    throw error;
  }
};

export const deleteCategoria = async (id: any) => {
  try {
    const token = Cookies.get("TokenBrasao");

    const formData = new FormData();
    formData.append("id", id.toString());

    const response = await fetch(`${API_URL}/delete`, {
      method: "POST",
      body: formData,
    });

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Falha ao deletar a conta bancaria:", error);
    throw error;
  }
};

// Métodos novos adicionados ao service

export const getProdutosPorCategoria = async (queryParams: string) => {
  try {
    const response = await fetch(`${API_URL}/lista-produtos?${queryParams}`);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error("Erro ao buscar Produtos:", error);
    throw error;
  }
};

// Cria uma nova Subcategoria (Nível 1) vinculada à categoria
export const createSubcategoriaNivel1 = async (subcategoria: any) => {
  try {
    const formData = new FormData();
    formData.append("nome", subcategoria.nome);
    formData.append("categoria_id", subcategoria.categoriaId);

    const response = await fetch(`${API_URL}/subcategoria-nivel1`, {
      method: "POST",
      body: formData,
    });

    if (!response.ok) {
      throw new Error("Falha na requisição");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Falha ao cadastrar Subcategoria Nível 1:", error);
    throw error;
  }
};

// Cria uma nova Subcategoria (Nível 2) vinculada à categoria
export const createSubcategoriaNivel2 = async (subcategoria: any) => {
  try {
    // Cria um FormData para enviar os dados da subcategoria
    const formData = new FormData();
    formData.append("nome", subcategoria.nome);
    formData.append(
      "subcategoria_nivel1_id",
      subcategoria.subcategoriaNivel1Id
    );
    formData.append("categoria_id", subcategoria.categoriaId);

    const response = await fetch(`${API_URL}/subcategoria-nivel2`, {
      method: "POST",
      body: formData,
    });

    if (!response.ok) {
      throw new Error("Falha na requisição");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Falha ao cadastrar Subcategoria Nível 2:", error);
    throw error;
  }
};

// Cria um novo Produto vinculado à categoria e à subcategoria (Nível 2)
export const createProduto = async (produto: any) => {
  try {
    // Prepara os dados do produto via FormData
    const formData = new FormData();
    formData.append("nome", produto.nome);
    formData.append("subcategoria_nivel2_id", produto.subcategoriaNivel2Id);
    formData.append("categoria_id", produto.categoriaId);

    const response = await fetch(`${API_URL}/produto`, {
      method: "POST",
      body: formData,
    });

    if (!response.ok) {
      throw new Error("Falha na requisição");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Falha ao cadastrar Produto:", error);
    throw error;
  }
};

// Obtém a lista de Subcategorias (Nível 1) vinculadas à categoria
export const getSubcategoriasNivel1PorCategoria = async (
  categoriaId: number
) => {
  try {
    const response = await fetch(
      `${API_URL}/lista-subcategorias-nivel1?categoriaId=${categoriaId}`
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error("Erro ao buscar Subcategorias (Nível 1):", error);
    throw error;
  }
};

export const deleteProduto = async (produto: any) => {};

export const updateProduto = async (produto: any) => {
  try {
    // Prepara os dados do produto via FormData
    const formData = new FormData();
    formData.append("id", produto.id);
    formData.append("nome", produto.nome);
    formData.append("subcategoria_nivel2_id", produto.subcategoriaNivel2Id);
    formData.append("categoria_id", produto.categoriaId);

    const response = await fetch(`${API_URL}/produto/update`, {
      method: "POST",
      body: formData,
    });

    if (!response.ok) {
      throw new Error("Falha na requisição");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Falha ao cadastrar Produto:", error);
    throw error;
  }
};

// Obtém a lista de Subcategorias (Nível 2) vinculadas à categoria
export const getSubcategoriasNivel2PorCategoria = async (
  categoriaId: number
) => {
  try {
    const response = await fetch(
      `${API_URL}/lista-subcategorias-nivel2?categoriaId=${categoriaId}`
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error("Erro ao buscar Subcategorias (Nível 2):", error);
    throw error;
  }
};
